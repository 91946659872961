import logo from "./logo.svg";
import "./App.css";
import max from "./max.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={max} className="App-logo" alt="logo" />
        <p>едрить задеплоил</p>
        {/* <img
          className="App-link"
          src={max}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </img> */}
      </header>
    </div>
  );
}

export default App;
